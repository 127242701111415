import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LogoText = styled.h1`
font-family: 'Comfortaa', cursive;
font-size: ${props => props.theme.fontxxl};
color: ${props => props.theme.text2};
transition: all 0.2s ease;

&:hover{
    transform: scale(1.1);
}

@media (max-width: 64em) {
  font-size: ${props => props.theme.fontxl};
}
`



const Logo = () => {
  return (
    <LogoText>
        <Link to ="/">
        3D Birds
        </Link>
    </LogoText>
  )
}

export default Logo