import React from 'react'
import styled from 'styled-components'
import TypeWriterText from '../TypeWriterText' 
import CoverImage from '../CoverImage'
import IMG3 from '../sections/Phone.png'

const Section = styled.section`
min-height: ${props => `calc(100vh - ${props.theme.navHeight})` };
width: 100%;
height: 100vh;
position: relative;
background-color: black;
`
const Container = styled.div`
width: 100%;
min-height: 80vh;
margin: 0 auto;
/* background-color: lightblue; */

display: flex;
justify-content: center;
align-items: center;



image{
  width: 100%;
  heigh: 100%;
  object-fit: cover;
}
`
const Box = styled.div`
width: 100%;
height: 100%;

display: flex;
top: 0%;
left: 0%;
position: absolute;

flex-direction: column;
justify-content: center;
align-items: center;
`

const Mint = () => {
  return (
    <Section id="mint">
        <Container>
        <img src={IMG3} />
          <Box> <TypeWriterText /> </Box>
        </Container>
      </Section>
  )
}

export default Mint