import React, {useState} from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import Button from './Button'
import Connection from '../utils/wallet_connect'
import Twitter from '../Icons/Twitter'
import Discord from '../Icons/Discord'
import Opensea from '../Icons/Opensea'
import Etherscan from '../Icons/Etherscan'

const Section = styled.section`
width: 100vw;
background-color: black;  
`
const NavBar = styled.nav`
display: flex;
justify-content: space-between;
align-items: center;

font-family: 'orbitron';

width: 90%;
height: ${props => props.theme.navHeight};
margin: 0 auto;

.mobile{
  display: none;
}

@media (max-width: 64em) {
 .desktop{
  display: none;
 }
 .mobile{
  display: inline-block;
}
}
`


const Menu = styled.ul`
display: flex;
justify-content: space-between;
align-items: center;
list-style:none;

@media (max-width: 64em) {
  /* 1024 px */



  position: fixed;
  top: ${props => props.theme.navHeight};
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: ${props => `calc(100vh - ${props.theme.navHeight})`};
  z-index: 50;
  background-color: ${props => `rgba(${props.theme.bodyRgba}, 0.85)`};
  backdrop-filter: blur(2px);

  transform: ${props => props.click ? 'translateY(0)' : 'translateY(100%)'};
  transition: all 0.3s ease;
  flex-direction: column;
  justify-content: center;


}
`

const MenuItems = styled.li`
margin: 0 1rem;
color: #a7a7a7;
cursor: pointer;
transition: all 0.2s ease;
position: releative;
&:hover{
  transform: scale(0.9);
}
`

const HamburgerMenu = styled.span`
width: ${props => props.click ? '2rem' : '1.5rem'};
height: 2px;
background: ${props => props.theme.text2};

position: absolute;
top: 2rem;
left: 90%;
transform: ${props => props.click ? 'translateX(-50%) rotate(90deg)' : 'translateX(-50%) rotate(0)'}; 

display: none;
justify-content: center;
align-items: center;

cursor: pointer;
transition: all 0.3s ease;

@media (max-width: 64em) {
  /* 1024 px */
  display: flex;
}

&::after, &::before{
  content: ' ';
  width: ${props => props.click ? '1rem' : '1.5rem'};
  height: 2px;
  right: ${props => props.click ? '-2px' : '0'};
  background: ${props => props.theme.text2};
  position: absolute;
  transition: all 0.3s ease;
}

&::after{
  top: ${props => props.click ? '0.3rem' : '0.5rem'};
  transform: ${props => props.click ? 'rotate(-40deg)' : 'rotate(0)'};
}
&::before{
  bottom: ${props => props.click ? '0.3rem' : '0.5rem'};
  transform: ${props => props.click ? 'rotate(40deg)' : 'rotate(0)'};
}
`

const Navigation = () => {

  const [click, setClick] = useState(false)

const scrollTo = (id) => {

  let element = document.getElementById(id);

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'

  })

  setClick(!click);
}

  return (
    
    <Section id="navbar">
      <NavBar>
        <Menu click={click}>
          <MenuItems><a href="https://etherscan.io/address/0xa1a515873d667639d06b9f39f13fa5e6045197a5"  target="_blank" rel="noreferrer"><Etherscan/></a></MenuItems>
          <MenuItems><a href="https://opensea.io/collection/ironinside"  target="_blank" rel="noreferrer"><Opensea/></a></MenuItems>
          <MenuItems><a href="https://discord.gg/ironinside"  target="_blank" rel="noreferrer"><Discord/></a></MenuItems>
          <MenuItems><a href="https://twitter.com/IronInside_NFT"  target="_blank" rel="noreferrer"><Twitter/></a></MenuItems>
          <MenuItems onClick={() => scrollTo('faq')} >Faq</MenuItems>
          <MenuItems>
          </MenuItems>
        </Menu>
        <div class="desktop">
        <Connection />
        </div>
      </NavBar>
    </Section>
  )
}
// <Button text="Connect Wallet">
export default Navigation
//<MenuItems onClick={() => scrollTo('mint')} >Mint</MenuItems>
//<MenuItems><a href="https://opensea.io/"  target="_blank" rel="noreferrer"><Opensea/></a></MenuItems>
//<MenuItems><a href="https://etherscan.io/"  target="_blank" rel="noreferrer"><Etherscan/></a></MenuItems>