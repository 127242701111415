// https://docs.metamask.io/guide/ethereum-provider.html#using-the-provider

import React from 'react'
import { useState, useEffect } from 'react';
import {ethers} from 'ethers'
// import Button from '../components/Button';
import styled from 'styled-components'
import {
    contractPaused, getfreeMaxMint,
    getfreeMintPrice,
    getfreeMintSupply,
    getUserMinted, getWlMintAmount,
    totalMintedOnContract,
    WLEnabled
} from './contract_utils'
import {contractABI, contractAddress} from "../contractConfig";

const Full = styled.div`
display: flex;
justify-content: center;
align-items: center;

width: 100%;
margin: 0 auto;
`

const PlusMinus = styled.div`
display: flex;
justify-content: center;
align-items: center;

width: 100%;
height: ${props => props.theme.navHeight};
margin: 0 auto;
`

const InputBlock = styled.input`
width: 5%;
font-family: orbitron;
text-align: center;
display: inline-block;
background-color: #a7a7a7;
color: ${props => props.theme.body2};
outline: none;
border: none;
font-size ${props => props.theme.fontsm};
padding: 0.8rem 0.5rem;
border-radius: 10px;
transition: all 0.2s ease;
position: releative;
font-weight: bolder;
`

const PaidButton = styled.button`
font-family: 'orbitron';
display: inline-block;
background-color: #a7a7a7;
color: ${props => props.theme.body2};
outline: none;
border: none;
font-weight: bolder;
margin: 5px;
font-size ${props => props.theme.fontsm};
padding: 0.9rem 2.3rem;
border-radius: 10px;
cursor: pointer;
transition: all 0.2s ease;
position: releative;
&:hover{
    transform: scale(0.9);
}


`

const Title = styled.h2`
font-size: ${props => props.theme.fontxxl}
text-transform: capitalize;
width: 100%;
color: red;
align-self: flex-start;
text-align:  center;
font-family: 'orbitron';
`

export default function Mint_contract() {
    const [mintDisabled, setMintDisabled] = useState(false)
    const [mintPaused, setMintPaused] = useState(false)
    const [totalMinted, setTotalMinted] = useState(0)
    const [freeMintSupply, setfreeMintSupply] = useState(0)
    const [wlMintEnabled, setWlMintEnabled] = useState(false)
    const [freeMintPrice, setfreeMintPrice] = useState(0)
    const [freeMaxMint, setfreeMaxMint] = useState(0)
    const [userMintedAmount, setUserMintedAmount] = useState(0)
    const [amountToMint, setAmountToMint] = useState(1)
    const [erorrWL, seterorrWL] = useState();
    const [PiadButtonzyx, setPiadButtonzyx] = useState();
    

    async function getProof(address) {
        return (await fetch(`api/getProof?address=${address}`)).json()
    }

    async function mint(amount) {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, contractABI, signer);
            const userMintAmount = await getUserMinted()

            if (totalMinted < freeMintSupply) {
                if (userMintAmount + amount > freeMaxMint) {
                    seterorrWL('User already minted free')
                    return
                }
                const transaction = await contract.mint(amount, {value: freeMintPrice * amount})
                console.log(transaction)
                const txReceipt = transaction.wait()
                console.log(txReceipt)
            }
        }
        catch (error) {
            console.error(error)
            seterorrWL('Error! Try again')
        }
    }

    async function mintWhitelist() {
        try {
            const userMintAmount = await getUserMinted()
            const wlMintAmount = await getWlMintAmount()
            console.log(wlMintAmount, userMintAmount)
            if (userMintAmount >= wlMintAmount) {
                seterorrWL('User already minted in whitelist')
                return
            }
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, contractABI, signer);
            const address = await signer.getAddress();

            const proofData = await getProof(address)

            if (proofData.error) {
                seterorrWL('User is not on a whitelist!');
                return
            }
            console.log(proofData)
            const transaction = await contract.mintWhitelist(
                proofData['proof']
            )
            console.log('WL Transaction:', transaction)
            const txReceipt = await transaction.wait()
            console.log('Transaction Receipt', txReceipt)

            return txReceipt

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(async () => {
        refresh();
        initStates();
        setInterval(function() {
            refresh();
            initStates();
            }, 10000);
    })

    const initStates = async () => {
        setfreeMintSupply(await getfreeMintSupply())
        setfreeMintPrice(await getfreeMintPrice())
        setfreeMaxMint(await getfreeMaxMint())
    }

    const refresh = async () => {
        setTotalMinted(await totalMintedOnContract())
        setMintPaused(await contractPaused())
        setWlMintEnabled(await WLEnabled())
        setUserMintedAmount(await getUserMinted())
    }

    const Freemint = async () => {
        let result
        seterorrWL('minting...')
        setMintDisabled(true)
        if (mintPaused) {
            seterorrWL('Minting is paused')
            refresh();
            setMintDisabled(false)
            return
        }
        console.log('Wl mint status', wlMintEnabled)
        if (wlMintEnabled) {
            console.log('Minting wl')
            result = await mintWhitelist()
        }
        else {
            let amount = amountToMint
            // Сделай инпут для количества и получай оттуда число
            console.log('Minting')
            result = await mint(amount)
        }

        console.log(result)
        setMintDisabled(false)
    }

      const handleDecrement = () => {
        if (amountToMint <= 1) return;
        setAmountToMint(amountToMint - 1);
      };
    
      const handleIncrement = () => {
        if (totalMinted < 100) {
            if (amountToMint >= 1) return;
            setAmountToMint(amountToMint + 1);
    } 
        else {
            if (amountToMint >= 3) return;
            setAmountToMint(amountToMint + 1);
      }
      };
    return (
        <>
            <Title>Total Minted: {totalMinted}/10000. {} </Title>
            
            <PlusMinus>
                <PaidButton onClick={() => handleDecrement()}>-</PaidButton>
                <InputBlock type="text" value={amountToMint} />
                <PaidButton onClick={() => handleIncrement()}>+</PaidButton>
            </PlusMinus>
            <Full>
                <PaidButton disabled={mintDisabled} onClick={() => Freemint()}>Mint</PaidButton>
            </Full>
            <>
            <Title>{erorrWL}</Title>
            </>
        </>
    )
}


