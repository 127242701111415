import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import {light} from './styles/Themes'
import { useState } from "react";
import Mint from "./components/sections/Mint";

import Navigation from './components/Navigation';
import Home from './components/sections/Home';
import Faq from './components/sections/Faq';
import ScrollToTop from "./components/ScrollToTop";
import Space from "./components/sections/Space";

function App() {
  return (
    <>
      
    <GlobalStyles />
    <ThemeProvider theme={light}>
    <Navigation />
    <Mint />
    <Faq />
    </ThemeProvider>
    </>
  );
}
// <Mint />
// <Space />
export default App;
