import * as React from "react"
import styled from "styled-components"

const Svg = styled.svg`
width: 30px;
height: 30px;
fill: #a7a7a7;
`

const Etherscan = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 323.77 293.68">
    <path id="Path_1" data-name="Path 1" d="M61.06,139.82A12.44,12.44,0,0,1,73.5,127.37h.06l20.74.07a12.46,12.46,0,0,1,12.47,12.46h0v78.42c2.33-.7,5.33-1.43,8.61-2.2a10.4,10.4,0,0,0,8-10.11V108.74a12.47,12.47,0,0,1,12.47-12.47h20.78a12.46,12.46,0,0,1,12.46,12.47V199s5.2-2.11,10.27-4.25a10.43,10.43,0,0,0,6.36-9.58V77.57A12.46,12.46,0,0,1,198.19,65.1H219a12.47,12.47,0,0,1,12.47,12.47v88.62c18-13.05,36.27-28.76,50.76-47.64A20.91,20.91,0,0,0,285.38,99,146.58,146.58,0,0,0,148.74,0C67.3-1.08,0,65.4,0,146.84a146.39,146.39,0,0,0,19.5,73.45,18.57,18.57,0,0,0,17.71,9.18c3.93-.35,8.82-.84,14.64-1.52a10.39,10.39,0,0,0,9.21-10.31V139.82" transform="translate(0 0)"/>
    <path id="Path_2" data-name="Path 2" class="cls-1" d="M60.6,265.58A146.81,146.81,0,0,0,293.77,146.84c0-3.39-.15-6.73-.38-10.05-53.64,80-152.68,117.4-232.79,128.79" transform="translate(0 0)"/>
  </Svg>
)

export default Etherscan
