import React, { useEffect } from 'react'
import styled from 'styled-components'
import TypeWriterText from '../TypeWriterText' 
import CoverImage from '../CoverImage'
import IMG from '../sections/BackDay.mp4'
import IMG2 from '../sections/BackNight.mp4'
import { useState } from 'react'

const Section = styled.section`
min-height: ${props => `calc(100vh - ${props.theme.navHeight})` };
width: 100%;
height: 100vh;
position: relative;
background-color: ${props => props.theme.body2};

@media (max-width: 64em) {
  max-height: 0;
  min-height: 40vh;
}
@media (max-width: 48em) {
  max-height: 0;
  min-height: 27vh;
}
`
const Container = styled.div`

width: 100%;
min-height: 80vh;
margin: 0 auto;
/* background-color: lightblue; */



video{
  width: 100%;
  heigh: 100%;
  object-fit: cover;
}
@media (max-width: 64em) {
  video{
    width: 100%;
    heigh: 100%;
    object-fit: contain;
  }
}
`
const Box = styled.div`
opacity: 0.8;
background-color: ${props => props.theme.text};
color: ${props => props.theme.body};
outline: none;
border: 2px solid #fff;

display: flex;
justify-content: center;
align-items: center;

top: 85%;
left: 46.2%;
position: absolute;

@media (max-width: 64em) {
  display: none;
}

@media (max-width: 120em) {
  top: 83%;
left: 43.5%;
}

font-size ${props => props.theme.fontsm};
padding: 0.2rem 0.3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: releative;
`
const MiniBox = styled.div`

opacity: 0.9;
display: inline-block;
background-color: #000;
color: ${props => props.theme.body};
outline: none;
border: none;

font-size ${props => props.theme.fontsm};
margin: 5px;
padding: 0rem 0rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: releative;
`

const BackButton = styled.button`
opacity: 0.9;
display: inline-block;
background-color: ${props => props.theme.text};
color: ${props => props.theme.body};
outline: none;
border: none;

font-size ${props => props.theme.fontsm};
padding: 0.9rem 2.3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: releative;
&:hover{
    transform: scale(0.9);
}

&::after{
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${props => props.theme.text};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;

}

&:hover::after{
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
}
`


export default function Home() {
  const [backGround, setbackGround] = useState(IMG2)

  const setBackNight = async () => {
      setbackGround(IMG2);
    };

  const setBackDay = async () => {
      setbackGround(IMG);
    };

  return (
    <Section id="home">
      <Container>
      <video src={backGround} autoPlay loop muted />
      <Box>
        <MiniBox><BackButton onClick={() => setBackNight()}>Night</BackButton></MiniBox>
        <MiniBox><BackButton onClick={() => setBackDay()}>Day</BackButton></MiniBox>
        </Box>
        </Container> 
      </Section>
  )
}
//      <Box>  </Box>
// <Box> <TypeWriterText /> </Box>
