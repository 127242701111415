import * as React from "react"
import styled from "styled-components"

const Svg = styled.svg`
width: 30px;
height: 30px;
fill: #a7a7a7;
@media (max-width: 64em) {
  /* 1024 px */
  fill: #202020;
}
`


const Opensea = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 90">
    <path d="M45,0A45,45,0,1,0,90,45,45,45,0,0,0,45,0ZM22.2,46.51l.19-.3L34.1,27.89a.4.4,0,0,1,.7.05c2,4.39,3.65,9.84,2.86,13.23a20.46,20.46,0,0,1-2.31,5,8,8,0,0,1-.44.74.39.39,0,0,1-.33.17h-12A.4.4,0,0,1,22.2,46.51Zm52.18,6.3a.41.41,0,0,1-.25.38c-.91.39-4,1.82-5.3,3.61C65.54,61.38,63,67.93,57.4,67.93H34A15.07,15.07,0,0,1,18.9,52.83v-.27a.4.4,0,0,1,.41-.4H32.37a.46.46,0,0,1,.44.5,4.41,4.41,0,0,0,.46,2.51,4.62,4.62,0,0,0,4.13,2.56h6.47v-5h-6.4a.42.42,0,0,1-.34-.65l.24-.34c.6-.87,1.46-2.2,2.33-3.72a31.19,31.19,0,0,0,1.61-3.22c.09-.2.16-.41.24-.6.13-.36.26-.69.35-1s.17-.57.24-.84a13.54,13.54,0,0,0,.3-2.95c0-.41,0-.83,0-1.23s-.07-.88-.13-1.32a9.76,9.76,0,0,0-.18-1.17c-.09-.58-.22-1.17-.36-1.75l-.06-.23c-.11-.4-.21-.78-.33-1.19C41,31.2,40.54,30,40.1,28.82c-.17-.46-.35-.9-.54-1.33-.27-.67-.55-1.27-.8-1.84-.13-.26-.24-.49-.35-.74s-.25-.54-.38-.8-.2-.39-.27-.57L37,22.09a.26.26,0,0,1,.29-.38l5,1.34h0l.64.19.72.2.27.07V20.57A2.55,2.55,0,0,1,46.41,18a2.46,2.46,0,0,1,1.79.76A2.55,2.55,0,0,1,49,20.57v4.37l.53.14.12.07.55.41c.19.14.38.32.62.51.48.39,1,.88,1.67,1.45.16.15.33.29.48.44.8.75,1.7,1.62,2.57,2.6l.72.84c.23.3.49.59.71.87s.6.8.88,1.22c.12.2.28.4.39.6.36.53.67,1.08,1,1.63.12.25.25.53.36.8a11.34,11.34,0,0,1,.75,2.24,2.24,2.24,0,0,1,.11.5v0a3.82,3.82,0,0,1,.09.7,7.82,7.82,0,0,1-.12,2.31c-.08.32-.17.63-.27,1s-.23.64-.37,1a14.07,14.07,0,0,1-1,1.88c-.13.22-.28.46-.43.68s-.33.46-.47.68-.43.56-.65.82a8.32,8.32,0,0,1-.62.79c-.31.37-.61.71-.92,1a7.1,7.1,0,0,1-.59.64c-.19.23-.4.42-.58.6l-.78.76-.52.46a.39.39,0,0,1-.27.11H49v5h5a4.58,4.58,0,0,0,3-1.12,36.15,36.15,0,0,0,3.07-3,.34.34,0,0,1,.18-.11l13.69-4a.4.4,0,0,1,.52.38v2.9Z"/> 
  </Svg>
)

export default Opensea
