import React from 'react'
import styled from 'styled-components'
import Typewriter from 'typewriter-effect';
import Button from './Button';
import Contract_mint_free from '../utils/contract_mint_free';
import Contract_mint_paid from '../utils/contract_mint_paid';
import Contract_mint_wl from '../utils/contract_mint_wl';
import Mint_contract from '../utils/mint_contract'



//backdrop-filter: blur(1px);
const Title = styled.h2`
font-size: ${props => props.theme.fontxxl}
text-transform: capitalize;
width: 100%;
color: ${props => props.theme.text2};
align-self: flex-start;
text-align: center;



span{
    
    font-family: 'orbitron', cursive;
    font-weight: bolder;
}
.text-1{
    color: #00e5ff;
}
.text-2{
    color: red;   
}
.text-3{
    color: #f5d332;
}

@media (max-width: 70em) {
  font-size: ${props => props.theme.fontxl};
}
@media (max-width: 48em) {
  align-self: center;
  text-align: center;
}
@media (max-width: 48em) {
  width: 90%;
}
`
const SubTitle = styled.h3`
opacity: 0.8;
font-size: ${props => props.theme.fontlg};
text-transform: capitalize;
color: ${props => props.theme.text2};
font-weight:600;
margin-bottom: 1rem;
width: 100%;
align-self: flex-start;
text-align:  center;
padding: 10px;

@media (max-width: 40em) {
  font-size: ${props => props.theme.fontmd};
  
}

@media (max-width: 48em) {
  align-self: center;
  text-align: center;
}
`

const ButtonContainer = styled.div`
width: 100%;
align-self: flex-start;

@media (max-width: 48em) {
  align-self: center;
  text-align: center;

  button{
    margin: 0 auto;
  }
}
`


const TypeWriterText = () => {
  return (
    <>
    <ButtonContainer>

        <Mint_contract/>
    </ButtonContainer>
    </>
  )
}


// <Contract_mint_wl/> <Contract_mint_free/> <Contract_mint_paid/>
export default TypeWriterText