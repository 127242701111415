import React from 'react'
// import GIF from '../assets/Home Video.mp4'
import styled from 'styled-components'
//import IMG from '../assets/moon3dbirds.png'
import IMG from '../assets/556465.png'

const ImageContainer = styled.div`
width: 85%;

img{
    width: 100%;
    heigh: auto;
}

@media (max-width: 64em) {
  min-width: 40vh;
}
`

const CoverImage = () => {
  return (
    <ImageContainer>
        <img src={IMG} type="556465.png" loop />
    </ImageContainer>
  )
}

export default CoverImage