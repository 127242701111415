// https://docs.metamask.io/guide/ethereum-provider.html#using-the-provider

import React from 'react'
import { useState, useEffect } from 'react';
import { ethers } from 'ethers'
// import Button from '../components/Button';
import styled from 'styled-components'
import { mintFreeContract, mintPayContract, totalMintedOnContract } from './contract_utils'
import {chainId} from "../contractConfig";

const MaskButton = styled.button`
display: inline-block;
background-color: black;
color: #a7a7a7;
outline: none;
border: none;

font-size ${props => props.theme.fontsm};
padding: 0.9rem 2.3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: releative;
&:hover{
    transform: scale(0.9);
}

`

export default function Connection() {
  const [install, setInstall] = useState(false)
  const [network, setNetwork] = useState(false)//False if wrong network
  const [account, setAccount] = useState(null)
  const [mintDisabled, setMintDisabled] = useState(false)
  const [totalMinted, setTotalMinted] = useState(0)
  const [tokenData, setTokenData] = useState([]);
  // const NEXT_PUBLIC_NET_VERSION = 1

  useEffect(async () => {
    setInstall(!window.ethereum)
    if (window.ethereum) {
      const networkVersion = await window.ethereum.request({ method: 'net_version' })
      console.log(networkVersion)
      setNetwork(networkVersion == chainId)
      window.ethereum.on('chainChanged', (networkVersion) => {
        console.log("chainChanged", networkVersion)
        let nv10 = parseInt(networkVersion);
        setNetwork(nv10 == chainId)
      })
      window.ethereum.on('accountsChanged', (accounts) => {
        console.log("accountsChanged", accounts, window.ethereum.selectedAddress)
        setAccount(window.ethereum.selectedAddress)
      })

      setAccount(window.ethereum.selectedAddress)
    }
  }, []);

  // useEffect(async () => {
  //   if (account && network) {
  //     await refresh();
  //   }
  // }, [account, network])

  const refresh = async () => {
    console.log("Getting token info from contractф")
    let total = await totalMintedOnContract()
    let totalDec = parseInt(total.hex);
    setTotalMinted(totalDec)

  }

  if (install) return <a href="https://metamask.io/" target="_blank" rel="noreferrer"><MaskButton>Install MetaMask</MaskButton></a>
  if (!network) return <MaskButton>Wrong Network</MaskButton>
  if (!account) return <><MaskButton onClick={() => window.ethereum.request({ method: "eth_requestAccounts" })}>Connect Wallet</MaskButton></>
  if (account) return <><MaskButton onClick={() => window.ethereum.request({ method: "eth_requestAccounts" })}>Connected</MaskButton></>


  return (
    <></>
  )
}

