import React from 'react'
import styled from 'styled-components'
import Accordion from '../Accordion'

const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
background-color: black;
position: relative;
color: ${(props) => props.theme.body};

display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

p{
  margin-top: 10px;
}

a{
  color: red;
  text-decoration: underline;
  text-decoration-color: red;
}
`

const Title = styled.h1`
font-size: ${props => props.theme.fontxxl};
text-transform: uppercase;
color: ${(props) => props.theme.body};

margin: 1rem auto;
border-bottom: 2px solid ${(props) => props.theme.body};
width: fit-content;

@media (max-width: 48em) {
  font-size: ${props => props.theme.fontxl};
}
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-items: center;

@media (max-width: 64em) {
  width: 80%;
}
@media (max-width: 48em) {
  width: 90%;
  flex-direction: column;
}
`

const Box = styled.div`
width: 70%;

`

const Faq = () => {
  return (
    <Section id="faq">
      
      <Box>
        <Accordion title="What is IronInside?">
        - A team of cool kids ready to show everyone the future using technology. So far, this is the first step. Every holder will be part of the events to come. Let's find out if you're a robot or a human...
        </Accordion>
        <Accordion title="When will there be a mint?">
        - September 5st.
        </Accordion>
        <Accordion title="Where will the mint be held?">
        - On its own website. Let's get closer to the mint.
        </Accordion>
        <Accordion title="How much will NFTs cost during minting?">
        - All NFTs will be completely free + gas.
        </Accordion>
        <Accordion title="How can I get into WL?">
        - Participate in the drawing and follow our social media.
        </Accordion>
        <Accordion title="How can I check if I won the raffle?">
        - Go to:
        <p><a href="https://premint.xyz/ironinside_nft"  target="_blank" rel="noreferrer">• premint.xyz/ironinside_nft</a></p>
        <p><a href="https://premint.xyz/ironinside_nfts"  target="_blank" rel="noreferrer">• premint.xyz/ironinside_nfts</a></p>
        <p>Or check the lists published in the discord channel.</p>
        </Accordion>
        <Accordion title="What site will the project roll out at?">
        - <a href="https://opensea.io/collection/ironinside"  target="_blank" rel="noreferrer">Opensea.</a>
        </Accordion>
        <Accordion title="How much NFT can I get during the minting of WL?">
        - Max 1 NFT per wallet.
        </Accordion>
        <Accordion title="How many NFT can I get in public mint?">
        - Max 3 NFTs per wallet.
        </Accordion>
      </Box>
      
    </Section>
  )
}

export default Faq