import React from 'react'
import styled from 'styled-components'
import Accordion from '../Accordion'

const Section = styled.section`
height: 5vh;
width: 100vw;
background-color: ${props => props.theme.text};
position: relative;
color: ${(props) => props.theme.body};

display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`

const Title = styled.h1`
font-size: ${props => props.theme.fontxxl};
text-transform: uppercase;
color: ${(props) => props.theme.body};

margin: 1rem auto;
border-bottom: 2px solid ${(props) => props.theme.body};
width: fit-content;

@media (max-width: 48em) {
  font-size: ${props => props.theme.fontxl};
}
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-items: center;

@media (max-width: 64em) {
  width: 80%;
}
@media (max-width: 48em) {
  width: 90%;
  flex-direction: column;
}
`

const Box = styled.div`
width: 45%;
p{
  margin-top: 10px;
}

@media (max-width: 64em) {
  width: 90%;
  align-self: center;
}
`

const Space = () => {
  return (
    <Section>
    </Section>
  )
}

export default Space